import './App.css';
import {
  useLocation,
  Outlet, useParams
} from 'react-router-dom';
import {useMembershipApplication} from "context/ApplicationContext";
import React, {useEffect, useState} from "react";
import ApiClient from "rest/ApiClient";
import {Circles} from "react-loader-spinner";

const LoadingApp = () => {
  return (
    <div
      style={{
        fontSize: 42,
        textAlign: 'center',
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "90%",
      }}
    >
      <Circles
        height="60"
        width="60"
        color= "#2767ff" //"#4fa94d"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      Loading...
    </div>
  )
}

const ErrorPage = ({description} : {description: string}) => {
  return (
    <div
      style={{
        color: 'red',
        fontSize: 56,
        textAlign: 'center',
        height: "100%",
      }}
    >
      Error<br/>
      <small>{ description }</small>
    </div>
  )
}

function App() {
  // const location = useLocation();
  const params = useParams();
  // let navigate = useNavigate();

  const { appState, setAppState } = useMembershipApplication();
  const [error, setError] = useState<string | undefined>(undefined);

  // useBeforeUnload(true);

  // usePrompt("dddd", true);

  // useBeforeUnload(
  //   useCallback((event) => {
  //       console.log("event: ", event);
  //       event.preventDefault();
  //       setTimeout(function () {
  //         const retVal = window.confirm("Do you want to continue ?");
  //         if (retVal === true) {
  //           alert("User wants to continue!");
  //           return true;
  //         }
  //         else {
  //           window.stop();
  //           return false;
  //         }
  //       })
  //     // return event.returnValue = "exit?";
  //     return
  //   }, [])
  // )

  useEffect(() => {

    // force viewport to stay full height after keyboard is shown
    // TODO: ONLY ON ANDROID STOCK BROWSER?
    const vp = document.querySelector("meta[name=viewport]")
    if (vp) {
      const windowHeight = window.innerHeight;
      vp.setAttribute("content", `height=${windowHeight}px, width=device-width, initial-scale=1.0`)
    }

    // if("virtualKeyboard" in navigator) {
    //   const vk = (navigator as any).virtualKeyboard;
    //   vk.overlaysContent = true;
    //   vk.addEventListener("geometrychange", () => {
    //     console.log("geometrychange", vk.boundingRect);
    //   })
    // }

    /*
    ApiClient.venueKey = 'pe';
    setAppState({...appState, theme: {
        "venueId": 17,
        "venueKey": "pe",
        "displayName": "Player Elite",
        "homePageRichHtml": "<p>Welcome to HYDRA for venue player elite</p>\n<ol>\n  <li>How about this first thing</li>\n  <li>And this second thing</li>\n  <li>Another third thing..</li>\n</ol>",
        "logoImgUrl": "https://s3-ap-southeast-2.amazonaws.com/pe-venue-landing-assets-prod/aatest/logo_hornsby.png",
        "cardBackgroundImgUrl": "https://s3-ap-southeast-2.amazonaws.com/pe-venue-landing-assets-prod/aatest/card_bg_theme.png",
        "coverBackgroundImgUrl": "https://s3-ap-southeast-2.amazonaws.com/pe-venue-landing-assets-prod/aatest/hornsby_background.png",
        "termsConditionsUrl": "https://google.com",
        "headerColor": "#006ACC",
        "venueState": "NSW",
        "membershipProducts": [
          {
            "stripeProductId": "price_1LxfZjGlCirudLBCO37Nis8E",
            "displayName": "1 Year",
            "displayPrice": "$11",
            "description": "This is a year long membership"
          },
          {
            "stripeProductId": "price_1LxfZjGlCirudLBCO37Nis8E",
            "displayName": "2 Year",
            "displayPrice": "$20",
            "description": "This is a 2 year long membership"
          }
        ]
      }});
    return
    */

    // const pathArray = location.pathname.split('/')
    // if (pathArray.length <= 1) {
    //   setError("invalid url")
    //   return
    // }
    // const key = pathArray[1];
    // if (key.length === 0) {
    //   setError("invalid url")
    //   return
    // }
    // ApiClient.venueKey = key;

    const venueKey = params['venueKey']
    if (!venueKey) {
      setError("invalid url")
      return
    }
    ApiClient.venueKey = venueKey;

    console.log(`APP - useEffect - ${ApiClient.venueKey}`);
    ApiClient.getVenueTheme()
      .then((theme: any) => {
        console.log(theme);
        setAppState({...appState, theme});

        // testing to confirm it handles no pension products correctly
        // setAppState({...appState, theme: { ...theme,
        //     newProducts: theme.newProducts.filter((p: any) => !p.isPensionerProduct),
        //     renewalProducts: theme.renewalProducts.filter((p: any) => !p.isPensionerProduct)
        // }});

        // force on extras for testing
        // setAppState({...appState, theme: {...theme,
        //   marketingShowGeneral: true,
        //   marketingShowGaming: true,
        //   marketingShowAnnualReport: true,
        //   showAutoRenew: true,
        //   showOccupation: true,
        //   venueState: 'sa'
        // }});

      })
      .catch((error) => {
        console.log(error.message);
        // did fail, show error with retry button
        setError(error.message);
        return;
      })

  }, [params]);

  if (error) {
    return <ErrorPage description={error} />
  }

  if (!appState.theme) {
    return <LoadingApp />
  }

  return (
    <>
        <div
          style={{
              width: '100%',
              height: '100%',
              overflowX: 'hidden',
              position: 'relative',
              // display: 'contents',
              display: 'flex',
              flexDirection: 'column'
          }}
          onContextMenu={(e) => {
              e.preventDefault();
              return false;
          }}
        >
          <Outlet />
        </div>
    </>
  )
}

export default App;
