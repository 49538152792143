import styled from "@emotion/styled";

const PromptContainer = styled.div`
  user-select: none;
  & > span {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 6px;
    display: block;
    color: #444;
  }
`;
const Title = styled.h3`
  text-align: center;
`;
const ButtonBar = styled.div`
  display: flex;
  gap: 5%;
  margin: 12px 0 8px 0;
`;
type ActionButtonProps = {
  primary?: boolean;
};
const ActionButton = styled.button<ActionButtonProps>`
  flex: 1;
  appearance: none;
  border-radius: 100px;
  padding: 10px;
  font-family: Montserrat, system-ui, sans-serif;
  font-weight: 600;
  background: ${(props) =>
    props.primary
      ? "linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%)"
      : "none"};
  border: ${(props) =>
    props.primary ? "none" : "rgba(172,159,84, 0.6) 1.5px solid"};
  color: ${(props) => (props.primary ? "white" : "rgb(55,56,53)")};

  &:active {
    opacity: 0.5;
  }
`;
const DetailSummary = styled.div`
  padding: 6px;
  font-weight: 600;
`;
const DetailSection = styled.div`
  padding: 4px 0;
`;
const FieldTitle = styled.div`
  display: inline;
  font-size: smaller;
  color: #444455;
  font-style: italic;
`;
const DisclaimerText = styled.div`
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  color: rgb(34 34 34 / 60%);
`;

type PersonInfo = {
  fullName: string;
  // docNumberDisplayText: string;
  // docIdentityNumber: string;
  // address?: string;
  licenceNumber: string;
  licenceAddress?: string;
  dateOfBirth: string;
  cardNumber?: string;
};
type ConfirmationDialogueProps = {
  info: PersonInfo;
  confirmTapped: () => void;
  declineTapped: () => void;
};

export const ConfirmationDialogue = ({
  info,
  confirmTapped,
  declineTapped,
}: ConfirmationDialogueProps) => {
  // const { fullName, dateOfBirth, address, docNumberDisplayText, docIdentityNumber } = info;
  const { fullName, dateOfBirth, licenceAddress, licenceNumber } = info;

  return (
    <PromptContainer>
      <Title>Check Details</Title>
      <span>
        Before proceeding please confirm the details below are correct.{" "}
      </span>
      <DetailSummary>
        <DetailSection>
          <FieldTitle>LicenceNumber: </FieldTitle>
          <br />
          <span>{licenceNumber}</span>
        </DetailSection>
        <DetailSection>
          <FieldTitle>Full name: </FieldTitle>
          <br />
          <span>{fullName.toUpperCase()}</span>
        </DetailSection>
        <DetailSection>
          <FieldTitle>Date of birth: </FieldTitle>
          <br />
          <span>{dateOfBirth}</span>
        </DetailSection>
        {!!licenceAddress && (
          <>
            <DetailSection>
              <FieldTitle>Address*: </FieldTitle>
              <br />
              <span>{licenceAddress}</span>
            </DetailSection>
            <DisclaimerText>
              *If your address differs to what's listed on your licence, you can
              update it after the next step.
            </DisclaimerText>
          </>
        )}
      </DetailSummary>
      <ButtonBar>
        <ActionButton onClick={declineTapped}>Retry</ActionButton>
        <ActionButton onClick={confirmTapped} primary>
          Confirm
        </ActionButton>
      </ButtonBar>
    </PromptContainer>
  );
};
