// @ts-nocheck
import CanvasDraw from "react-canvas-draw";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { forwardRef, RefObject, useEffect, useRef, useState } from "react";
import {
  MembershipProduct,
  useMembershipApplication,
} from "context/ApplicationContext";
import ApiClient, { MarketingPreferences } from "rest/ApiClient";
import Base64toBlob from "util/base64toBlob";
import { uploadSignature } from "rest/imageUploadFlow";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import { useNavigate } from "react-router-dom";
import MarketingToggle, {
  MarketingOption,
} from "components/marketing-toggle/MarketingToggle";
import { Checkbox, FormControlLabel } from "@mui/material";
import AsyncSelect from "react-select/async";
import TermsConditionsPanel from "components/terms-conditions-panel/TermsConditionsPanel";
import { sampleTermsHtml } from "pages/signup/SignUpFormPage";
import { animateScroll, getScrollMidY } from "util/scrollHelper";
import {
  Container,
  PageSubHeading,
  ConfirmButton,
  PageHeading,
  TermsContainer,
  SignatureContainer,
  MembershipButton,
  CheckoutContainer,
  CheckoutButton,
  SecurityFooter,
  FormLabel,
} from "./FormSegements.styled";
import { FormPageType, HydraOccupation } from "./types";
import ContactInfoForm from "./ContactInfoForm";
import { CSSObjectWithLabel } from "react-select";

const AdditionalInfoForm = forwardRef(
  ({ pageOffset, onComplete }: FormPageType, ref) => {
    const { appState, userInfo, setUserInfo } = useMembershipApplication();
    const { showOccupation } = appState.theme!;

    const [processingRequest, setProcessingRequest] = useState(false);
    const [selectedOccupation, setSelectedOccupation] =
      useState<HydraOccupation | null>(null);
    // const [isPensioner, setIsPensioner] = useState<boolean>(false);

    const fetchOccupations = (
      inputValue: string,
      callback: (options: HydraOccupation[]) => void
    ) => {
      ApiClient.getOccupations(inputValue).then((response: any) => {
        console.log(response);
        if (response.data.success) {
          callback(response.data.occupations);
        }
      });
    };

    const tappedConfirm = () => {
      if (!showOccupation) {
        onComplete();
        return;
      }

      if (!selectedOccupation) return;

      if (processingRequest) return;
      setProcessingRequest(true);

      ApiClient.saveOccupation(
        selectedOccupation.hydraWageJobCodeATO,
        appState.flowToken!
      ).then((response: any) => {
        console.log(response);
        setTimeout(() => {
          setProcessingRequest(false);
        }, 2000);

        if (response.data.success) {
          onComplete();
        }
      });
    };

    // const setPensioner = (pensioner: boolean) => {
    //   setIsPensioner(pensioner);
    //   setUserInfo({ ...userInfo });
    //
    //   ApiClient.setPensioner(pensioner, appState.flowToken!)
    //     .then((response: any) => {
    //       console.log(response);
    //     })
    //     .catch((error) => console.error(error.message));
    // };

    useEffect(() => {
      if (userInfo.isPensionEligible) {
        const retiredOccupation: HydraOccupation = {
          hydraWageJobCodesID: 1419,
          hydraWageJobCodeATO: "999999",
          hydraWageJobNameATO: "Retired",
        };
        setSelectedOccupation(retiredOccupation);
      }
    }, []);

    return (
      <Container pageOffset={pageOffset} ref={ref as RefObject<HTMLDivElement>}>
        <PageSubHeading>
          We need a bit more information
          <br />
          before we can proceed
        </PageSubHeading>
        <div
          style={{
            width: "100%",
            padding: 6,
            display: "flex",
            flexDirection: "column",
            gap: 6,
            boxSizing: "border-box",
          }}
        >
          {showOccupation && (
            <>
              <FormLabel>Occupation</FormLabel>
              <AsyncSelect
                styles={{
                  container: (baseStyles) =>
                    ({
                      ...baseStyles,
                      width: "100%",
                    } as CSSObjectWithLabel),
                  control: (baseStyles) =>
                    ({
                      ...baseStyles,
                      border: "rgb(226, 228, 235) 1px solid",
                      borderRadius: 8,
                    } as CSSObjectWithLabel),
                }}
                value={selectedOccupation}
                onChange={setSelectedOccupation}
                loadOptions={fetchOccupations}
                getOptionLabel={(option) => option.hydraWageJobNameATO}
                maxMenuHeight={250}
                placeholder="Start typing..."
                openMenuOnFocus
                isClearable
                isSearchable
              />
            </>
          )}
          {/* {showPensioner && !!userInfo.isPensionEligible && (
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label="I'm a Pensioner"
              control={
                <Checkbox
                  checked={isPensioner}
                  onChange={(e) => {
                    setPensioner(e.target.checked);

                    //if pensioner and no occupation is selected, set the occupation to retired
                    if (
                      e.target.checked &&
                      showOccupation &&
                      !selectedOccupation
                    ) {
                      ApiClient.getOccupations('retired')
                        .then((response: any) => {
                          console.log(response);
                          if (
                            response.data.success &&
                            response.data.occupations
                          ) {
                            const retiredOccupation =
                              response.data.occupations[0];
                            setSelectedOccupation(retiredOccupation);

                            console.log(retiredOccupation);
                          }
                        })
                        .catch((error) => {
                          console.error(error.message);

                          // NOTE: use default occupation id?
                          const retiredOccupation: HydraOccupation = {
                            hydraWageJobCodesID: 1419,
                            hydraWageJobCodeATO: '999999',
                            hydraWageJobNameATO: 'Retired',
                          };
                          setSelectedOccupation(retiredOccupation);
                        });
                    }
                  }}
                />
              }
            />
          </div>
        )} */}
          {(!showOccupation || selectedOccupation) && (
            <ConfirmButton onClick={tappedConfirm}>Confirm</ConfirmButton>
          )}
        </div>
      </Container>
    );
  }
);

const SignatureView = forwardRef(
  ({ pageOffset, onComplete }: FormPageType, ref) => {
    const { appState, userInfo, setUserInfo } = useMembershipApplication();
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const canvasRef = useRef<CanvasDraw>(null);
    const [canvasData, setCanvasData] = useState<string | undefined>(undefined);
    const [processingRequest, setProcessingRequest] = useState(false);

    useEffect(() => {
      if (canvasData) {
        canvasRef.current?.loadSaveData(canvasData, true);
      }
    }, []);

    const saveSignature = async () => {
      if (!canvasData) return;

      // block button while saving
      if (processingRequest) return;
      setProcessingRequest(true);
      setTimeout(() => {
        setProcessingRequest(false);
      }, 2000);

      const signature = (canvasRef.current! as any).getDataURL(
        "png",
        false,
        "white"
      );
      const updatedInfo = { ...userInfo, signature };
      setUserInfo(updatedInfo);

      try {
        const { imageName, imageBlob } = await Base64toBlob(
          signature,
          "signature"
        );
        const response = await uploadSignature(
          imageName,
          imageBlob,
          appState.flowToken!
        );
        if (!response || !response.success) {
          throw "";
        }

        console.log("response: ", response);
      } catch (e) {
        console.error("submit failed: ", e);
        return;
      }

      onComplete();
    };

    const onDraw = (canvas: CanvasDraw) => {
      setCanvasData(canvas.getSaveData());
    };

    const clearCanvas = () => {
      canvasRef.current?.clear();
      setCanvasData(undefined);
    };

    return (
      <Container pageOffset={pageOffset} ref={ref as RefObject<HTMLDivElement>}>
        <PageHeading>Terms & Conditions</PageHeading>
        <TermsContainer accepted={acceptedTerms}>
          {acceptedTerms ? (
            <>
              <span>
                I confirm all details are true and correct, and I agree to the
                terms of conditions
              </span>
              <SignatureContainer>
                <span>Draw your signature below</span>
                <div>
                  <CanvasDraw
                    ref={canvasRef}
                    backgroundColor={"transparent"}
                    canvasWidth={300}
                    canvasHeight={160}
                    lazyRadius={0}
                    brushRadius={2}
                    onChange={onDraw}
                    immediateLoading
                    hideGrid
                    hideInterface
                  />
                  <DeleteForeverRoundedIcon
                    onClick={clearCanvas}
                    style={{
                      display: canvasData !== undefined ? "block" : "none",
                      color: "white",
                      background: "red",
                      padding: "4px",
                      borderRadius: "4px",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  />
                </div>
              </SignatureContainer>
            </>
          ) : (
            <>
              <TermsConditionsPanel
                tosRichHtml={appState.theme?.tosRichHtml ?? sampleTermsHtml}
                onAcceptTerms={() => setAcceptedTerms(true)}
              />
            </>
          )}
        </TermsContainer>
        {canvasData !== undefined && (
          <ConfirmButton onClick={saveSignature}>Confirm</ConfirmButton>
        )}
      </Container>
    );
  }
);

const MarketingOptions = forwardRef(
  ({ pageOffset, onComplete }: FormPageType, ref) => {
    const { userInfo, appState } = useMembershipApplication();
    const hideEmail = (userInfo.email ?? "").length === 0;
    const [generalMarketingPref, setGeneralMarketingPref] =
      useState<MarketingOption>("SMS");
    const [gamingMaterialPref, setGamingMaterialPref] =
      useState<MarketingOption>("SMS");
    const [annualReportPref, setAnnualReportPref] = useState<MarketingOption>(
      hideEmail ? "POST" : "EMAIL"
    );
    const [autoRenewMembership, setAutoRenewMembership] = useState(true);
    const [processingRequest, setProcessingRequest] = useState(false);

    const {
      marketingShowGeneral,
      marketingShowGaming,
      marketingShowAnnualReport,
      showAutoRenew,
    } = appState.theme!;
    const showAnyToggle =
      marketingShowGeneral || marketingShowGaming || marketingShowAnnualReport;

    const onConfirm = () => {
      if (!appState.flowToken) return;
      if (processingRequest) return;
      setProcessingRequest(true);

      const marketingOptions: MarketingPreferences = {
        receiveGeneralMarketing: null,
        receiveGamingMaterial: null,
        receiveClubsAnnualReport: null,
        autoRenewMembershipWithPoints: null,
      };
      if (marketingShowGeneral)
        marketingOptions.receiveGeneralMarketing = generalMarketingPref;
      if (marketingShowGaming)
        marketingOptions.receiveGamingMaterial = gamingMaterialPref;
      if (marketingShowAnnualReport)
        marketingOptions.receiveClubsAnnualReport = annualReportPref;
      if (showAutoRenew)
        marketingOptions.autoRenewMembershipWithPoints = autoRenewMembership;

      // Save preferences
      ApiClient.saveMarketing(marketingOptions, appState.flowToken ?? "")
        .then((response: any) => {
          console.log(response);
          setTimeout(() => {
            setProcessingRequest(false);
          }, 2000);

          const {
            data: { success },
          } = response;
          if (success) {
            onComplete();
          }
        })
        .catch((error: any) => {
          setProcessingRequest(false);
          alert(error.message);
        });
    };

    return (
      <Container pageOffset={pageOffset} ref={ref as RefObject<HTMLDivElement>}>
        <PageHeading>Personal Preferences</PageHeading>
        {showAnyToggle && (
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            {marketingShowGeneral && (
              <MarketingToggle
                name="general-marketing"
                onToggleChange={setGeneralMarketingPref}
                hideEmail={hideEmail}
              />
            )}
            {marketingShowGaming && (
              <MarketingToggle
                name="gaming-material"
                onToggleChange={setGamingMaterialPref}
                hideEmail={hideEmail}
              />
            )}
            {marketingShowAnnualReport && (
              <MarketingToggle
                name="annual-report"
                initialValue={annualReportPref}
                onToggleChange={setAnnualReportPref}
                showPostOption
                hideEmail={hideEmail}
              />
            )}
          </div>
        )}
        {showAutoRenew && (
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label="Auto renew my membership from points (when available)"
              control={
                <Checkbox
                  defaultChecked={autoRenewMembership}
                  onChange={(e) => setAutoRenewMembership(e.target.checked)}
                />
              }
            />
          </div>
        )}
        <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
      </Container>
    );
  }
);

const filterPensionerProducts = (
  products: MembershipProduct[],
  pensioner: boolean
) => {
  const filteredProducts = products.filter(
    (p) => p.isPensionerProduct === pensioner
  );
  return filteredProducts.length ? filteredProducts : products;
};
const MembershipSelection = forwardRef(
  ({ pageOffset, onComplete }: FormPageType, ref) => {
    const navigate = useNavigate();
    const { userInfo, appState, setAppState } = useMembershipApplication();
    const [selectedMembership, setSelectedMembership] = useState<
      MembershipProduct | undefined
    >(undefined);
    const [processingRequest, setProcessingRequest] = useState(false);

    const memberProductsContainerRef = useRef<HTMLDivElement | null>(null);
    const membershipProducts = userInfo.currentMemberInfo?.isUserAMember
      ? filterPensionerProducts(
          appState.theme!.renewalProducts,
          !!userInfo.isPensionEligible
        )
      : filterPensionerProducts(
          appState.theme!.newProducts,
          !!userInfo.isPensionEligible
        );

    const confirmSelection = () => {
      if (!selectedMembership) return;

      setAppState({
        ...appState,
        venueMembershipProductId: selectedMembership.venueMembershipProductId,
      });

      if (selectedMembership.skipPayment) {
        console.log("selected free membership");
        if (!appState.nextFlowGuid || !appState.flowToken) return;

        if (processingRequest) return;
        setProcessingRequest(true);

        ApiClient.checkoutFree(
          selectedMembership.venueMembershipProductId,
          appState.flowToken
        ).then((response: any) => {
          console.log(response);
          setTimeout(() => {
            setProcessingRequest(false);
          }, 2000);

          const {
            data: { success, requestGuid },
          } = response;
          if (success && requestGuid) {
            onComplete(true);
            setTimeout(() => {
              navigate(
                `/${ApiClient.venueKey}/complete?selectedId=${appState.venueMembershipProductId}&flow=${requestGuid}&token=${appState.flowToken}`,
                { replace: true }
              );
            }, 50);
          }
        });
        return;
      }

      onComplete();
    };

    return (
      <Container
        pageOffset={pageOffset}
        style={{ width: "100%", margin: 0, gap: 0 }}
        ref={ref as RefObject<HTMLDivElement>}
      >
        <PageHeading>Membership Type</PageHeading>
        <div
          ref={memberProductsContainerRef}
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "calc(var(--root-view-width) * 0.04)",
            fontSize: "min(calc(var(--root-view-width) * 0.04), 20px)",
            userSelect: "none",
            padding: "6px 20px",
            boxSizing: "border-box",
            maxHeight: `calc(100vh - var(--root-view-width) * ${
              !!selectedMembership ? 1.05 : 0.75
            })`,
            overflowY: "auto",
          }}
        >
          {membershipProducts?.map((membership, index) => {
            return (
              <MembershipButton
                key={index}
                selected={membership === selectedMembership}
                onClick={(e) => {
                  setSelectedMembership(membership);

                  if (!memberProductsContainerRef.current) return;
                  if (
                    memberProductsContainerRef.current.scrollHeight >
                    memberProductsContainerRef.current.clientHeight
                  ) {
                    const scrollToY = getScrollMidY(
                      e.currentTarget,
                      memberProductsContainerRef.current
                    );
                    animateScroll(
                      memberProductsContainerRef.current,
                      scrollToY,
                      300
                    );
                  }
                }}
              >
                <div>
                  <h3 style={{ margin: 0 }}>{membership.displayName}</h3>
                  <div style={{ height: 2, borderRadius: 1, width: "22%" }} />
                  <h3 style={{ margin: 0 }}>{membership.displayPrice}</h3>
                </div>
                <div>
                  <small>{membership.description}</small>
                  <strong>Total Cost: {membership.displayPrice}</strong>
                </div>
              </MembershipButton>
            );
          })}
        </div>
        {selectedMembership && (
          <ConfirmButton onClick={confirmSelection}>Confirm</ConfirmButton>
        )}
      </Container>
    );
  }
);

const PaymentView = forwardRef(
  ({ pageOffset, onComplete }: FormPageType, ref) => {
    const navigate = useNavigate();
    const { appState } = useMembershipApplication();
    const [processingRequest, setProcessingRequest] = useState(false);

    const checkoutCash = () => {
      console.log("selected pay with cash");
      if (
        !appState.nextFlowGuid ||
        !appState.flowToken ||
        !appState.venueMembershipProductId
      )
        return;

      if (processingRequest) return;
      setProcessingRequest(true);

      ApiClient.checkoutCash(
        appState.venueMembershipProductId,
        appState.flowToken
      ).then((response: any) => {
        console.log(response);
        setTimeout(() => {
          setProcessingRequest(false);
        }, 2000);

        const {
          data: { success, requestGuid },
        } = response;
        if (success && requestGuid) {
          onComplete(true);
          setTimeout(() => {
            navigate(
              `/${ApiClient.venueKey}/complete?cashRequestGuid=${requestGuid}&selectedId=${appState.venueMembershipProductId}&flow=${appState.nextFlowGuid}&token=${appState.flowToken}`,
              { replace: true }
            );
          }, 50);
        }
      });
    };

    const checkoutStripe = () => {
      if (!appState.flowToken || !appState.venueMembershipProductId) return;

      if (processingRequest) return;
      setProcessingRequest(true);

      ApiClient.checkoutStripe(
        appState.venueMembershipProductId,
        appState.flowToken ?? ""
      ).then((response: any) => {
        console.log(response);
        setTimeout(() => {
          setProcessingRequest(false);
        }, 2000);

        const {
          data: { success, checkoutUrl },
        } = response;
        if (success) {
          onComplete(true);
          setTimeout(() => {
            window.location.replace(checkoutUrl);
          }, 50);
        }
      });
    };

    return (
      <Container pageOffset={pageOffset} ref={ref as RefObject<HTMLDivElement>}>
        <PageHeading>Payment</PageHeading>
        <CheckoutContainer>
          {!appState.theme?.removeCashPayment && (
            <CheckoutButton onClick={checkoutCash}>
              Pay with cash
            </CheckoutButton>
          )}
          <CheckoutButton onClick={checkoutStripe}>
            <img
              style={{ height: "100%" }}
              src={`../assets/images/stripe.svg`}
            />
            Checkout
          </CheckoutButton>
          <SecurityFooter>
            <span>Your connection is secure</span>
            <VerifiedUserRoundedIcon
              style={{ fontSize: "2.5em" }}
              fontSize={"inherit"}
            />
          </SecurityFooter>
        </CheckoutContainer>
      </Container>
    );
  }
);

export const FormSection = ({
  showAdditionalInfoPage,
  showMarketingPage,
  stage,
  onNext,
}: {
  showAdditionalInfoPage: boolean;
  showMarketingPage: boolean;
  stage: number;
  onNext: (done: boolean) => void;
}) => {
  const pageRef = useRef<HTMLDivElement | null>(null);
  const formPages = [
    { Component: ContactInfoForm, key: "contactInfoForm" },
    { Component: SignatureView, key: "signatureView" },
    { Component: MembershipSelection, key: "membershipSelection" },
    { Component: PaymentView, key: "paymentView" },
  ];
  if (showAdditionalInfoPage)
    formPages.splice(1, 0, {
      Component: AdditionalInfoForm,
      key: "additionalInfoForm",
    });

  const signaturePageIndex = formPages.findIndex(
    (page) => page.key === "signatureView"
  );
  if (showMarketingPage)
    formPages.splice(signaturePageIndex + 1, 0, {
      Component: MarketingOptions,
      key: "marketingOptions",
    });

  return (
    <>
      {formPages.map(({ Component, key }, index) => (
        <Component
          key={key}
          ref={pageRef}
          pageOffset={index - stage}
          onComplete={(done: boolean = false) => {
            onNext(done);
            // console.log(`Page ${index} completed:`, done);
          }}
        />
      ))}
    </>
  );
};
